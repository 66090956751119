.team-radar-div {
    margin-top: 0rem;
}

.team-radar-div-spacing {
    position: absolute;
    margin-top: 0rem;
}

.game {
    height: 22rem;
    width: 100%;
    display: flex;
    margin: auto;
    justify-content: center;
}

.game-spacing {
    height: 22rem;
    width: 100%;
    display: flex;
    margin: auto;
    justify-content: center;
}

.team-radar-spacing {
    height: 22rem;
    width: 27rem;
}

.team-radar {
    height: 22rem;
    width: 27rem;
}

/* PHONE VIEWS */
@media (max-width: 600px) {
    .team-radar-spacing {
        margin-top: 4.5rem;
        height: 50vw;
        width: 90vw;
    }

    .team-radar {
        height: 50vw;
        width: 90vw;
    }

    .game {
        height: 16rem;
        width: 100%;
        display: flex;
        margin: auto;
        justify-content: center;
    }
}
