:root {
    --mainblue: #0082e6;
    --secondblue: #1b9bff;
    --white: white;
    --font-large: 16px;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
    text-decoration: none;
}

.main {
    display: block;
    margin: auto;
    padding: 4vw;
    padding-top: 1vw;
    justify-content: center;
    align-content: center;
    max-width: 1900px;
}

.hide {
    display: none;
    visibility: hidden;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
