.team-standing {
    text-align: left;
    vertical-align: middle;
}

.team-standing-element {
    display: inline-block;
    vertical-align: middle;
}

.division-standings,
.conference-standings,
.league-standings {
    padding-top: 3em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.afc-divisions,
.nfc-divisions {
    justify-content: center;
    align-content: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.afc-conference,
.nfc-conference {
    display: flex;
}

#team-standing-name {
    display: inline-block;
    height: auto;
    width: 260px;
    vertical-align: middle;
}

#team-standing-img {
    padding: 10px;
    width: 10%;
}

#team-standing-wins
#team-standing-losses
#team-standing-hyphen {
    width: 20px;
}

.hidden {
    display: none;
}

@media screen and (max-width: 1400px) { 
    .afc-divisions,
    .nfc-divisions {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) { 
    .afc-divisions,
    .nfc-divisions {
        grid-template-columns: repeat(1, 1fr);
    }
}
