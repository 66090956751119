ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    max-width: 400px;
}

.team-standing {
    text-align: left;
    vertical-align: middle;
}

.team-standing-element {
    display: inline-block;
    vertical-align: middle;
}

.conference-standings {
    display: flex;
    justify-content: center;
}

.afc-divisions,
.nfc-divisions {
    display: flex;
    padding-bottom: 4em;
    padding-left: 1em;
    padding-right: 1em;
}

.afc-conference,
.nfc-conference {
    display: flex;
    padding-left: 3em;
    padding-right: 3em;
}

#team-standing-name {
    display: inline-block;
    height: auto;
    width: 260px;
    vertical-align: middle;
}

#team-standing-img {
    padding: 10px;
    width: 10%;
}

#team-standing-wins
#team-standing-losses
#team-standing-hyphen {
    width: 20px;
}

.hidden {
    display: none;
}

.welcomeBlock {
    text-align: center;
    justify-content: center;
}

.helpMenu {
    text-align: left;
    display: inline-block;
    margin-top: 1em;
    margin-bottom: 1em;
    border-collapse: collapse;
}

.helpMenuPage {
    font-weight: bold;
    padding-right: 1em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
}

.helpMenuPageDesc {
    padding-top: 0.2em;
    padding-bottom: 0.2em;
}

.helpMenuRow {
    border-bottom: 4px solid #CCCCCC;
}

.helpMenuRow:last-child {
    border-bottom: none;
}
