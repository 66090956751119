.upcoming-game-time {
    color: white;
    font-size: 1.5em;
    font-weight: bolder;
    font-style: italic;
    text-align: center;
    background-color: black;
}

.upcoming-game-div {
    height: 22rem;
    width: 100%;
    display: flex;
}

.upcoming-game-half {
    width: 100%;
    border-style: solid;
    border-width: 5px;
    background-color: #AAAAAA;
    height: 100%;

    .away-div {
        float: left;
        height: 100%;
        text-align: center;
    }
    .home-div {
        float: right;
        height: 100%;
        text-align: center;
    }

    & h1 {
        margin-top: -10%;
        font-size: 2.5rem;
    }

    & img {
        width: auto;
        height: auto;
        max-width: 90%;
        max-height: 90%;
        margin-top: 2rem;
    }
}

/* TABLET VIEWS */
@media (max-width: 1070px) {
    .upcoming-game-half {
        .away-div {
            margin-left: -1.5vw;
        }
        .home-div {
            margin-right: -1.5vw;
        }

        & img {
            max-width: 85%;
            max-height: 85%;
            margin-top: 3rem;
        }
    }
}

@media (max-width: 930px) {
    .upcoming-game-half {
        .away-div {
            margin-left: -2vw;
        }
        .home-div {
            margin-right: -2vw;
        }

        & img {
            max-width: 80%;
            max-height: 80%;
            margin-top: 4rem;
        }
    }
}

@media (max-width: 850px) {
    .upcoming-game-half {
        .away-div {
            margin-left: -4vw;
        }
        .home-div {
            margin-right: -4vw;
        }

        & img {
            max-width: 70%;
            max-height: 70%;
            margin-top: 5.5rem;
        }
    }
}

@media (max-width: 750px) {
    .upcoming-game-half {
        .away-div {
            margin-left: -5vw;
        }
        .home-div {
            margin-right: -5vw;
        }

        & img {
            max-width: 65%;
            max-height: 65%;
            margin-top: 6rem;
        }
    }
}

@media (max-width: 660px) {
    .upcoming-game-half {
        .away-div {
            margin-left: -5.5vw;
        }
        .home-div {
            margin-right: -5.5vw;
        }

        & img {
            max-width: 65%;
            max-height: 65%;
            margin-top: 6rem;
        }
    }
}

/* PHONE VIEWS */
@media (max-width: 600px) {

    .upcoming-game-half {
        .away-div {
            margin-left: 0;
        }
        .home-div {
            margin-right: 0;
        }

        & img {
            margin-top: .5rem;
        }

        & h1 {
            font-size: 1rem;
        }
    }

    .upcoming-game-time {
        font-size: 1.2em;
    }
}
