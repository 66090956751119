.indicator {
    margin: auto;
    position:relative;
    background-color: black;
    width: 100%;
    display: block;
}

.loading-spinner-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  max-height: 7rem;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}