.standingsContainer {
    height: auto;
    display: block;
    padding: 1em;
    padding-top: 0;
    margin: 1em;
}

.divisionName {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
    text-align: center;
    font-weight: bold;
    letter-spacing: .1rem;
    width: 70%;
    margin: 1rem auto;
    padding: 0 0rem;
    font-size: 1.5rem;
}


@media screen and (max-width: 768px) {
    .listOfTeams {
        width: 90vw;
    }
}

@media screen and (min-width: 768px) {
    .listOfTeams {
        max-width: 35em;
        min-width: 20em;
    }
}
