.streak-card-container {
    width: 100em;
    height: auto;
    display: block;
    padding: 1em;
    padding-top: 0;
    margin: 1em;
}

.streak-card-name {
    padding: .7vw;
    margin: 0;
    font-size: 1.1rem;
    font-style: italic;
}

.streak-card-team-tile-div {
    display: flex;
    margin-top: .5em;
    margin-bottom: .5em;
}

@media (max-width: 600px) {
    .streak-card-name {
        font-size: 1rem;
    }
}
