.break {
    flex-basis: 0%;
    height: 0;
}

.cardCategory {
    flex-grow: 2;
}

.singleCategory {
    margin: auto;
    justify-content: center;
    align-content: center;
}

.customMode {
    border: 1px solid magenta;
}

.categoryHolderDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

@media screen and (max-width: 768px) {
    .singleCategory,
    .multipleCategories {
        width: 90vw;
    }
}

@media screen and (min-width: 768px) {
    .singleCategory {
        max-width: 41em;
        min-width: 30em;
    }

    .multipleCategories {
        max-width: 35em;
        min-width: 30em;
    }

    .analyticsCards-link-multipleCategories {
        width: 35em;
    }

    .analyticsCards-link-singleCategory {
        width: 100em;
    }
}
