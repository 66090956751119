.streak-cards-container {
    justify-content: center;
    align-content: center;
}

.streak-cards-card-container {
    display: flex;
    margin: auto;
    justify-content: center;
    align-content: center;
}

.streakCards-p-message {
    text-align: center;
}

@media screen and (max-width: 768px) {
    .streak-cards-card-container {
        width: 90vw;
    }
}

@media screen and (min-width: 768px) {
    .streak-cards-card-container {
        width: 40rem;
    }
}
