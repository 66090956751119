.haveAnIdeaContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: auto;
    padding: 20px;
    background-color: var(--mainblue);
    color: var(--white);
    box-shadow: 0 8px 40px -12px rgba(0,0,0,0.3);
}

.haveAnIdeaContainer h2 {
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
}

.haveAnIdeaContainer label {
    text-align: center;
    margin-top: -1rem;
    margin-bottom: 2rem;
    font-size: 15px;
    letter-spacing: 1px;
}

.haveAnIdeaContainer input, .haveAnIdeaContainer textarea {
    padding: 10px;
    width: 100%;
    outline: none;
    border: none;
    margin: .5rem 0;
    max-width: 50rem;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-tap-highlight-color: transparent;
}

.haveAnIdeaContainer textarea {
    height: 10em; 
}

.haveAnIdeaContainer button {
    margin-top: 1rem;
    width: 50%;
    max-width: 20rem;
    font-size: 15px;
    letter-spacing: 0rem;
    padding: 10px;
    background-color: White;
    color: black;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.haveAnIdeaContainer button:hover {
    background-color: var(--secondblue);;
    color: var(--white);;
}

.title {
    background-color: var(--secondblue);;
    padding: 10px;
}

/* MOBILE VIEW BELOW */

@media screen and (max-width: 979px) {
    .haveAnIdeaContainer button:hover {
        background-color: var(--white);;
        color: black;
    }
}

/* 0 - 720 */
@media screen and (max-width: 720px) {
    .haveAnIdeaContainer {
        max-width: 90%;
        width: 90%;
    }
}