.teamFilter-div-logos {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.teamFilter-img-logo {
    width: 4rem;
    margin: auto;
}

.teamFilter-div-logo {
    margin: 0.1rem;
    width: 5rem;
    height: 5rem;
    padding: 0.2rem;
    display: flex;
    cursor: pointer;
}

.teamFilter-div-logoSelected {
    background-color: #cccccc;
    border-radius: 9rem;
    z-index: 3;
}

.teamFilter-div-containerMobile {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
    max-width: 300px;
    margin: .5rem auto;
}

.teamFilter-div-containerDesktop {
    display: block;
}

.teamFilter-div-logoGroup {
    margin: auto;
    display: flex;
    border: 1px solid lightgray;
    position: relative;
    z-index: 1;
    margin-bottom: 1rem;
    padding-top: 0.3rem;
}

.teamFilter-p-divisionName {
    position: absolute;
    top: -13px;
    left: 0;
    right: 0;
    text-align: center;
    display: table;
    margin: 0 auto;
    background-color: rgba(255, 255, 255);
    background-clip: padding-box;
    border-radius: 5px;
    z-index: 2;
    padding-left: 10px;
    padding-right: 10px;
    color: lightgray;
}

.teamFilter-mobile-dropdown {
    position: relative;
    margin-left: auto;
}

.teamFilter-label-name {
    font-weight: bold;
    font-size: 1.2rem;
    padding: 0 1rem 0 0;
}

.teamFilter-dropdown-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    background-color: #0082e6;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    width: 150px;
}

.teamFilter-dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 150px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 0; /* Add this line */
    transform: top right;
    max-height: 40vh;
    overflow-y: auto;
}

.teamFilter-dropdown-content label {
    display: block;
    padding: 8px 16px;
    cursor: pointer;
}

.teamFilter-mobile-dropdown:hover .teamFilter-dropdown-content {
    display: block;
}

.teamFilter-mobile-dropdown input[type="checkbox"] {
    margin-right: 8px;
}

.teamFilter-dropdown-carrot {
    margin-left: 5px;
    width: 13px;
    height: 13px;
    transition: transform 0.3s ease;
    transform: rotate(270deg); 
}

.rotate {
    transform: rotate(360deg); 
}

.teamFilter-button-text {
    flex: 1;
    margin-right: 0.5rem;
}

.teamFilter-button-icon {
    margin-left: 0.5rem;
    margin-right: -0.3rem;
}

@media screen and (max-width: 1500px) { 
    .teamFilter-div-logos {
        grid-template-columns: repeat(2, 1fr);
    }   
}

@media screen and (max-width: 768px) { 
    .teamFilter-div-logos {
        grid-template-columns: repeat(1, 1fr);
    } 
}