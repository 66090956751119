.navlink {
    color: white;
    display: inline-block;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: .1rem;
    text-align: center;
    white-space: nowrap; /* Prevent text from wrapping */
    padding: 15px 25px;
}

li.active, .navlink:hover {
    background-color: var(--secondblue);
    transition: .6s;
}

.clickableLogo {
    margin-top: .4rem;
    margin-left: -.5rem;
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

.logo {
    height: 6rem;
}

.socialLogo {
    height: 1.5rem;
    margin: 1rem 1rem;
    margin-top: 1.3rem;
    transform-origin: center; 
    transition: transform 0.5s;
}

.socialLogo:hover {
    transform: rotate(360deg);
}

.siteTitle {
    color: white;
    font-size: 2rem;
    line-height: 3rem;
    margin-left: -30rem;
    font-weight: bold;
}

.testMode {
    margin-left: 1rem;
}

@media screen and (max-width: 970px) {
    .siteTitle {
        display: none;
    }

    .testMode {
        margin-left: -3rem;
        font-size: 0.8rem;
    }
}


@media screen and (max-width: 720px) {
    .logo {
        height: 5rem;
    }
}