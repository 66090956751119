.categoryContainer {
    height: auto;
    display: block;
    padding: 1em;
    padding-top: 0;
    margin: 1em;
}

.categoryHeader {
    padding: .7vw;
    margin: 0;
    font-size: 1.1rem;
    font-style: italic;
}

.teamContainer {
    display: flex;
    margin-top: .5em;
    margin-bottom: .5em;
}

.ranking:not(.hide) {
    display: inline;
    padding-right: 0.4em;
}

.rankingCircle {
    margin: auto;
    width: 2.2em;
    height: 2.2em;
    background-color: black;
    border-radius: 50%;
    align-items: center;
    display: flex;
}

.rankingNumber {
    color: white;
    width: 100%;
    text-align: center;
    font-weight: bold;
}

.upArrow, .downArrow {
    margin: auto;
    margin-top: 0.1em;
    margin-bottom: 0.1em;
    width: 0;
    height: 0;
    border: 0.6em solid transparent;
}

.upArrow {
    border-top: 0;
}

.downArrow {
    border-bottom: 0;
}

.category-link {
    display:inline-flex;
    flex: wrap;
    width: 100%;
}

@media (max-width: 600px) {
    .categoryHeader {
        font-size: 1rem;
    }
}
