/* DESKTOP CSS */
.tag-filter-desktop-div-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 0.5rem;
    text-align: center;
}

.tag-filter-desktop-div-container button {
    margin: 5px;
    padding: 8px 15px;
    font-size: 15px;
    letter-spacing: 0.1rem;
    white-space: nowrap;
    background-color: transparent;
    color: black;
    transition: background-color 0.3s ease-in-out;
}

.tag-filter-desktop-div-container button.selected {
    background-color: #0082e6;
    color: white;
}
.tag-filter-desktop-div-container button:hover {
    background-color: #1b9bff;
    color: white;
    cursor: pointer;
}

/* MOBILE CSS */
.tag-filter-mobile-div-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
    max-width: 300px;
    margin: .5rem auto;
}

.tag-filter-dropdown-button {
    display: flex;
    padding: 1rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    background-color: #0082e6;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    width: 150px;
}

.tag-filter-dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 150px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 0;
    transform: top right;
    max-height: 40vh;
    overflow-y: auto;
}

.tag-filter-mobile-dropdown:hover .tag-filter-dropdown-content {
    display: block;
}

.tag-filter-mobile-dropdown {
    position: relative;
    margin-left: auto;
}

.tag-filter-dropdown-content div {
    padding: 10px 0; 
    cursor: pointer;
}

.tag-filter-dropdown-carrot {
    margin-left: 5px;
    width: 13px;
    height: 13px;
    transition: transform 0.3s ease;
    transform: rotate(270deg); 
}

.tag-filter-label-name {
    font-weight: bold;
    font-size: 1.2rem;
}

.rotate {
    transform: rotate(360deg); 
}

.tag-filter-button-text {
    flex: 1;
    margin-right: 0.5rem;
}

.tag-filter-button-icon {
    margin-left: 0.5rem;
    margin-right: -0.3rem;
}