.card {
    width: 100%;
    display: flex;
}

.team-logo-div {
    width:6em;
    max-width: 4em;
    max-height: 3em;
    min-width: 4em;
    min-height: 3em;
    display: flex;
    padding: 0.3vw;
    align-content: center;
    justify-content: center;
    margin-right: .1vw;
}

.team-logo {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    margin-left: 1vw;
    margin-right: 1vw;
}

h1 {
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    font-size: medium;
}

.location-name {
    min-width: 5em;
    width: 10vw;
}

h1.name {
    letter-spacing: .1rem;
}

h1.location {
    font-size: 0.7em;
    margin-top: 0.6em;
    margin-bottom: -0.4em;
}

h1.score {
    margin-left: auto;
    margin-right: 1.5vw;
    min-width: 4em;
    text-align: right;
}
