.upcoming-games-title {
    text-align: center;
    font-size: 2em;
    font-weight: bold;
    margin: auto;
    padding-top: 0.3em;
}

.upcoming-games-week {
    text-align: center;
    font-size: 1.5em;
    margin: auto;
    padding-top: 0.3em;
}

.outerUpcomingGamesDiv {
    padding: 2rem 0;
}
