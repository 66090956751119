.standingsFilter-div-container {
    text-align: center;
}

button.standingsFilter-button-toggle {
    width:  30%;
    height: 4rem;
    background-color: darkgrey;
    border: none;
    align-content: center;
    font-weight: bold;
    font-size: 1rem;
    color: white;
    cursor: pointer;
}

button.standingsFilter-button-toggle:not(:last-child) {
    border-right: none;
}

button.standingsFilter-button-toggle:hover {
    color: white;
    background-color: #1b9bff;
}

button.standingsFilter-button-toggle.active {
    color: white;
    background-color: #0082e6;
}

/* TABLET VIEWS */
@media (max-width: 900px) {
    button.standingsFilter-button-toggle {
        font-size: 2vw;
    }
}

/* PHONE VIEWS */
@media (max-width: 700px) {
    button.standingsFilter-button-toggle {
        font-size: 3vw;
        height: 5rem;
    }
}
