.streak-team-tile-card {
    width: 100%;
    height: 3em;
    display: flex;
    align-self: center;
}

.streak-team-tile-logo-container {
    width: 5.5rem;
}

.streak-team-tile-logo {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.streak-team-tile-name {
    float: left;
}

h1.streak-team-tile-score {
    margin-left: auto;
    margin-right: 1.5vw;
}

@media (max-width: 600px) {
    .streak-team-tile-logo {
        max-width: 12vw;
    }

    h1.streak-team-tile-name, h1.streak-team-tile-score {
        font-size: 0.8rem;
    }

    .streak-team-tile-logo-container {
        width: 4rem;
    }
}
