.seasonFilter-div-container {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    user-select: none;
}

.seasonFilter-fontAwesomeIcon-caret {
    color: #0082e6;
    height: 4rem;
    width: 3rem;
    cursor: pointer;
}

.seasonFilter-fontAwesomeIcon-caret:hover {
    color: #1b9bff;
}
