.analytics-options {
    text-align: center;
    margin: auto;
}

.teams-drop-down {
    margin-right: 2em;
}

.analyticsView-button-showHideFilters {
    cursor: pointer;
    display: block;
    margin: auto;
    color: white;
    background-color: #0082e6;
    border: none;
    padding: 1rem 0;
    width: 20rem;
    font-weight: bold;
    font-size: 1.2rem;
    letter-spacing: .1rem;
    margin-bottom: 1rem;
}

.analyticsView-button-showHideFilters:hover {
    background-color: #1b9bff;
    transition: .6s;
}

@media screen and (max-width: 768px) {
    .analytics-options {
        width: 90%;
    }
}